import React from 'react'

const Footer = () => {
	const currentYear = new Date().getFullYear();
	return (
		<div id="site-footer">
			<div>
				<a href="https://twitter.com/Luismacha" target="_blank" rel="noopener noreferrer">twitter</a>
				<br></br>
				<a href="https://www.linkedin.com/in/luis-m-castaneda/" target="_blank" rel="noopener noreferrer">linkedin</a>
				<br></br>
				<a href="https://github.com/luismachita" target="_blank" rel="noopener noreferrer">github</a>
			</div>
				<br></br>
				<p className="copyright">&copy; luis m castañeda {currentYear}</p>
		</div>
	)
}

export default Footer